<template>
  <div>
    <div class="Contacts">
      <Header />
      <div class="Contacts-Form">
        <FormSection />
      </div>
    </div>  
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: "Contacts",
  components: {
    FormSection,
    Header,
    Footer
  },
  metaInfo: {
    title: 'Our Contacts - WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content:"Here You Can Find How to Contact With Us ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],
  }
}
</script>

<style lang="scss">
  .Contacts {
    &-Form {
      transition: opacity 0.5s ease-in-out;

      &:not(&--ready) {
        opacity: 0;
      }
    }
  }
</style>
